import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';

const decodeHtml = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
}

const PostCard = (props) => {
    const decodedContent = decodeHtml(props.content);
    
    return (
        <Link to={`/post/${props.id}`} style={{ textDecoration: 'none' }}>
            <Card sx={{ maxWidth: 345 }}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe">
                            R
                        </Avatar>
                    }
                    title={
                        <Typography variant="h6" component="div" fontWeight="bold">
                            {props.title}
                        </Typography>
                    }
                />
                <CardMedia
                    component="img"
                    height="220"
                    image={props.image}
                    alt="Post Image"
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        <div dangerouslySetInnerHTML={{ __html: decodedContent }} />
                    </Typography>
                </CardContent>
            </Card>
        </Link>
    );
}

export default PostCard;
