import React from 'react';
import PostCard from '../Components/PostCard';
import { Box, Container, Grid } from '@mui/material';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import ArrowTopNavigation from '../Components/ArrowTopNavigation';

// Import images from the local folder
import PostImage1 from '../assets/images/REstore1.jpg';
import PostImage2 from '../assets/images/REstore3.jpg';
import PostImage3 from '../assets/images/REstore4.jpg';

const Blog = () => {
    // Hardcoded posts data for UI demonstration
    const posts = [
        {
            _id: '1',
            title: 'Construction of Underground Drainage',
            content: 'Building an underground drainage system is a critical aspect of modern infrastructure. It helps manage wastewater and stormwater, preventing flooding and promoting sanitation. Here’s a general step-by-step guide to the construction of an underground drainage system.',
            image: { url: PostImage1 }, // Use imported image
            createdAt: '2023-09-10',
            comments: [],
            likes: [],
        },
        {
            _id: '2',
            title: 'Design and Construction of Oil Drilling Location',
            content: 'Designing and constructing an oil drilling location is a complex process that requires careful planning, engineering expertise, and adherence to environmental and safety standards. Below is a detailed guide that outlines the key steps and considerations involved in the process.',
            image: { url: PostImage2 }, // Use imported image
            createdAt: '2023-08-22',
            comments: [],
            likes: [],
        },
        {
            _id: '3',
            title: 'Shore Production',
            content: 'Shore production refers to the processes and activities associated with extracting and managing natural resources, particularly oil and gas, from offshore or nearshore locations. Once resources are extracted from offshore fields, they are transported to onshore facilities',
            image: { url: PostImage3 }, // Use imported image
            createdAt: '2023-07-15',
            comments: [],
            likes: [],
        }
    ];

    return (
        <>
            <Box sx={{ bgcolor: "#fafafa", minHeight: "100vh" }}>
                <Navbar />
                <div style={{ textAlign: 'left', marginTop: '20px' }} className='title container'>
                    <h2>Blog</h2>
                    <div className='sub-title'></div>
                </div>

                <Container sx={{ pt: 5, pb: 5, minHeight: "83vh" }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} justifyContent="center">
                            {
                                posts.map((post, index) => (
                                    <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                                        <PostCard
                                            width="100%"
                                            id={post._id}
                                            title={post.title}
                                            content={post.content}
                                            image={post.image ? post.image.url : ''}
                                            subheader={post.createdAt}
                                            comments={post.comments.length}
                                            likes={post.likes.length}
                                            style={{ objectFit: 'cover' }}
                                            className="post-card"
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                </Container>
                <Footer />
                <ArrowTopNavigation />
            </Box>
        </>
    );
}

export default Blog;
