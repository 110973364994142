import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Row, Col, Form, Input, Button, Typography, Collapse, message } from 'antd';
import Navbar from '../Components/Navbar';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Styles/Carrer.css';
import Footer from '../Components/Footer';
import ArrowTopNavigation from '../Components/ArrowTopNavigation';
import Testimonials1 from '../assets/images/testinomial1.png';
import Testinomial2 from '../assets/images/Testinomial2.png';
import ParkerPlant from '../assets/images/parkerplant.jpg';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const Carrer = () => {
    const [submitted, setSubmitted] = useState(false);
    const [form] = Form.useForm();
    
    const posts = [
        {
            title: "Supervisor",
            content: "<p>The Supervisor is responsible for overseeing the day-to-day operations on the construction site, ensuring that the work progresses as planned. They coordinate with the site engineer, contractors, and laborers to monitor tasks, ensure safety standards are met, and resolve any on-site issues. Their primary duties include checking project progress, managing the workforce, ensuring that resources are used efficiently, and adhering to project timelines. They also enforce safety regulations and report to higher management on project updates.</p>"
        },
        {
            title: "Site Engineer",
            content: "<p>The Site Engineer is tasked with the technical aspects of the construction project. They are responsible for interpreting construction drawings, specifications, and ensuring that the construction work is executed correctly. They liaise with the project design team, check the quality of materials used, oversee site surveys, and ensure compliance with building regulations. The site engineer also collaborates with the supervisor to resolve any technical issues that may arise during construction. They play a key role in managing the engineering aspects of the project while ensuring that work is completed within budget and on time.</p>"
        }
    ];

    const owlOptions = {
        items: 1,
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        nav: true,
        dots: true,
        navText: ['<', '>'],
        responsive: {
            0: { items: 1 },
            768: { items: 1 },
            992: { items: 1 }
        }
    };

    useEffect(() => {
        AOS.init({ duration: 1000, once: true });
    }, []);

    const onFinish = (values) => {
        console.log('Submitted values:', values);
        setSubmitted(true);
        form.resetFields();
        message.success('Form submitted successfully!');
    };

    return (
        <div>
            <Navbar />
            <div className="carrer-banner">
                <div className="container carrer-text animation-top-sentence">
                    <h1 className='w3-animate-right'>Career</h1>
                </div>
            </div>
            <div className='container'> 
                <Row gutter={[16, 16]} className='pt-5'>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} className='pt-5'>
                        <Collapse defaultActiveKey={['available-jobs']} className="mt-4" data-aos="fade-right" data-aos-duration="1000">
                            <Panel header="Available Jobs" key="available-jobs" className='custom-panel'>
                                {posts.map((post, index) => (
                                    <div key={index}>
                                        <h3>{post.title}</h3> 
                                        <div dangerouslySetInnerHTML={{ __html: post.content }} />
                                    </div>
                                ))}
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className='pb-5' data-aos="fade-left" data-aos-duration="1000">
                        <div className="contact-form-container">
                            <Form form={form} onFinish={onFinish} layout="vertical">
                                <Title level={2} className='parkerTitle pb-4'>Join With Us</Title>
                                {submitted && <Paragraph type="success">Form submitted successfully!</Paragraph>}
                                <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter your name' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please enter your email' }, { type: 'email', message: 'Please enter a valid email' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="phone" label="Phone Number" rules={[{ required: true, message: 'Please enter your phone number' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="address" label="Address" rules={[{ required: true, message: 'Please enter your address' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="resume" label="Upload Resume">
                                    <Input type="file" />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Submit</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row justify="center" style={{ marginTop: '20px' }} className='container'>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} className='testimonals-content p-5' data-aos="fade-right" data-aos-duration="1000">
                    <h3 className='pb-5'>Client's Testimonials</h3>
                    <OwlCarousel className="owl-theme" {...owlOptions}>
                        <div className="item testimonials-content">
                            <img src={Testimonials1} alt="Testimonial 1" />
                            <Title level={4}>Shell Nigeria - Nigeria</Title>
                            <Paragraph>
                                Perfect company to work very consistent in project delivery.
                            </Paragraph>
                        </div>
                        <div className="item testimonials-content">
                            <img src={Testinomial2} alt="Testimonial 2" />
                            <Title level={4}>Shell Nigeria - Nigeria</Title>
                            <Paragraph>
                                Perfect company to work very consistent in project delivery.
                            </Paragraph>
                        </div>
                    </OwlCarousel>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} data-aos="fade-left" data-aos-duration="1000">
                    <Row justify="center">
                        <Col>
                            <img src={ParkerPlant} alt="Parker Plant" style={{ maxWidth: '100%', height: 'auto' }} />
                        </Col>
                    </Row>
                    <Row justify="left" className='parkerTitle'>
                        <Col>
                            <Title level={4} className='parkerTitle'>About ParkerPlant</Title>
                        </Col>
                    </Row>
                    <Row justify="left">
                        <Col>
                            <Paragraph className='para-justify '>
                                Parker Plant is a market leader in the manufacture and supply of reliable solutions for asphalt, crushing, screening, sand and gravel, concrete and material handling equipment throughout the World.
                            </Paragraph>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Footer />
            <ArrowTopNavigation />
        </div>
    );
}

export default Carrer;
