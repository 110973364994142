import React from 'react';
import { Collapse } from 'antd';
import '../Styles/Collapse.css'

const { Panel } = Collapse;

const items = [
  {
    key: '1',
    label: 'Construction of Airstrip',
    content: (
      <p className='collapse-content-para'>
      We have constructed several Helipads for the Oil Sector, it was a big achievement when we were awarded the contract to construct the AirStrip for Shell Petroleum Company in Forcados Terminal, Delta State. Apart from being an airstrip, it was an airstrip in a remote island in Delta State and the only access to it was by water, 95% of the material had to be moved by barges with more than a 1000 trips of 500 tonnes.
      </p>
    ),
  },
  {
    key: '2',
    label: 'Construction of Bridges',
    content: (
      <p className='collapse-content-para'>
     Bridge construction is among our major area of specialization. We have been involved in construction of bridges since the inception of our company. We have constructed various bridges across the country ranging from single span to multi span steel deck and concrete types of bridges.
      </p>
    ),
  },
  {
    key: '3',
    label: 'Building of Houses',
    content: (
      <p className='collapse-content-para'>
        Building of solid houses and field logistic base has been an integral part of Raycon. We have partake in building of fields logistic base for International Oil Companies on land and offshore. We also have a wide range of building products and systems which are aimed primarily at groups of building types or markets. The design process for buildings is highly organized and draws upon research establishments that study material properties and performance, code officials who adopt and enforces
      </p>
    ),
  },
  {
    key: '4',
    label: 'Shore Protection',
    content: (
      <p className='collapse-content-para'>
     We are highly experience in shore protection and restoration works. Our projects involved the protection of the coast and the shore against the erosive forces of waves, current and storm surge. We have implored high technical measures in protection of the coast and hinterland against flooding mainly in the Niger Delta Area of Nigeria.
      </p>
    ),
  },
  {
    key: '5',
    label: 'Pump Houses & Metering Stations ',
    content: (
      <p className='collapse-content-para'>
     Coming Soon!
      </p>
    ),
  },
];

const CollapseService2 = () => (
  <Collapse bordered={false} defaultActiveKey={['1']} style={{ marginBottom: 24 }}>
    {items.map(item => (
      <Panel header={<span style={{ color: '#203556', fontWeight: '600', fontSize: '20px' }}>{item.label}</span>} key={item.key}>
        {item.content}
      </Panel>
    ))}
  </Collapse>
);

export default CollapseService2;
