import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Collapse, Row, Col, theme } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import '../Styles/Home.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Principal from '../assets/images/truck.jpg'
import Jcp from '../assets/images/jcp.jpg'
import equipments3 from '../assets/images/equipments3.jpg'
import equipments4 from '../assets/images/equipments4.jpg'
import equipments5 from '../assets/images/equipments5.jpg'
import equipments6 from '../assets/images/equipments6.jpg'
import Background from '../assets/images/back.jpg'


const { Panel } = Collapse;

const getItems = () => [
  {
    key: '1',
    label: 'Why Raycon & Company is Best?',
    content: 'Our years of experience has demonstrated our competence, thus positioning us as a leading/major player in the industry.',
  },
  {
    key: '2',
    label: 'Why Raycon & Company is different?',
    content: 'Since inception, we have completed a great number of projects than any other construction company in Nigeria, making us a leading player in the industry.',
  },
  {
    key: '3',
    label: 'Why Raycon & Company is preferred?',
    content: '99.9% of our clients say they are satisfied after the completion of numerous projects.',
  },
  {
    key: '4',
    label: 'Why We Work Better?',
    content: 'Our collaborative approach to project design, execution, and implementation.',
  },
];

const Collapses = () => {
  useEffect(() => {
    AOS.init({
      // Global settings
      duration: 1000,
      once: true // Whether animation should happen only once
    });
  }, []); 
  const { token } = theme.useToken();

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  const paragraphStyle = {
    background: '#203556',
    padding: '10px',
    borderRadius: '5px',
    color: 'white',
  };

  return (
    <Row gutter={[16, 16]} className='collapse-section'>
      <Col xs={{ span: 24 }} md={{ span: 12 }} className='choosen-raycon' style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover', backgroundPosition: 'center' }} data-aos="fade-right" data-aos-duration="1000">
        <div >
        <h3>Why Choose Raycon</h3>
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          style={{ background: token.colorBgContainer }}
        >
          {getItems().map(item => (
            <Panel header={item.label} key={item.key} style={panelStyle}>
              <p className="redBackground" style={paragraphStyle}>{item.content}</p>
            </Panel>
          ))}
        </Collapse>
        </div>
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 12 }}  data-aos="fade-left" data-aos-duration="1000">
      <div className='OurEquipment' style={{ paddingRight:'40px',paddingLeft:'40px' }}>
        <h3>Our Equipment</h3>
        <OwlCarousel
        className="owl-theme"
        loop
        margin={10}
        nav
        navText={['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>']}
        responsive={{
          0: { items: 1 },
          600: { items: 1 },
          1000: { items: 1 }
        }}
      >
        <div className="item equipments-content">
          <img src={Principal} alt="Image 1" className='OurEquipment-img'/>
          <h5>Heavy Duty Dumper Trucks</h5>
          <p>25 - 40 Tonnes Dumper Trucks to Haul material.</p>
        </div>
        <div className="item equipments-content">
          <img src={Jcp} alt="Image 1" className='OurEquipment-img'/>
          <h5>Excavator</h5>
          <p>Mobile CAT Excavator</p>
        </div>
        <div className="item equipments-content">
          <img src={equipments3} alt="Image 1" className='OurEquipment-img'/>
          <h5>Slip Form Machine</h5>
          <p>Equipment to Lay Central Reservation, Kerbs or Walkways.</p>
        </div>
        <div className="item equipments-content">
          <img src={equipments4} alt="Image 1" className='OurEquipment-img'/>
          <h5>Asphalt Paving Equipment</h5>
          <p>Paver for Paving Roads.</p>
        </div>
        <div className="item equipments-content">
          <img src={equipments5} alt="Image 1" className='OurEquipment-img'/>
          <h5>200 TPH</h5>
          <p>Asphalt Plant Produces 200 Ton Per Hour</p>
        </div>
        <div className="item equipments-content">
          <img src={equipments6} alt="Image 1" className='OurEquipment-img'/>
          <h5>Cat Grader</h5>
          <p>Grader For Sand paving</p>
        </div>
        {/* Add more slides here */}
      </OwlCarousel>
      </div>
      </Col>
    </Row>
  );
};

export default Collapses;
