import React, { useRef } from 'react';
import { Carousel} from 'antd';
import { UpOutlined ,DownOutlined } from '@ant-design/icons';
import BackgroundImg1 from '../assets/images/letsconnet3.png';
import BackgroundImg2 from '../assets/images/headerimage2.jpg';
import BackgroundImg3 from '../assets/images/construction.jpg';

import '../Styles/CardCarosel.css'
import '../Styles/RecentWork.css'


const slideStyle = {
  position: 'relative', // Set position to relative for positioning text
  height: '400px', // Adjust the height as needed
};

const contentStyle = {
  position: 'absolute', // Position the content absolutely within the slide
  top: '50%', // Center vertically
  left: '50%', // Center horizontally
  transform: 'translate(-50%, -50%)', // Center the content perfectly
  color: '#fff',
  textAlign: 'center',
  fontSize: '40px', // Font size
  fontWeight: 700, // Font weight
};

const textContents = [ "Construction of Underground drainages","Standard and Dual Carriage Roads", "Design and Construction of Oil Drilling Location"]; // Text content for each slide

const images = [BackgroundImg1, BackgroundImg2,BackgroundImg3]; // Add more images as needed

const RecentWorkSlider = () => {
  const carouselRef = useRef(null);

  const nextSlide = () => {
    carouselRef.current.next();
  };

  const prevSlide = () => {
    carouselRef.current.prev();
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '500px' }}>
      <Carousel effect="fade" ref={carouselRef}>
        {images.map((image, index) => (
          <div key={index} style={slideStyle}>
            <img src={image} alt={`Slide ${index + 1}`} style={{ width: '100%', height: '500px' }} />
            <div style={contentStyle}>
              <h3  className='recent-content ' style={{ fontSize: '60px' ,fontWeight:'700'}}>{textContents[index]}</h3> {/* Set font size of h3 to match contentStyle */}
            </div>
          </div>
        ))}
      </Carousel>
      <div style={{ position: 'absolute', bottom: '1px', right: '1px', display: 'flex', flexDirection: 'column' }}>
        <button className='recent-btn' onClick={prevSlide} style={{ backgroundColor: '#203556', border: 'none', padding: '20px' }}><UpOutlined className='recent-btn-icon' style={{ color: '#fff' }} /></button>
        <button className='recent-btn' onClick={nextSlide} style={{ backgroundColor: '#203556', border: 'none', padding: '20px' }}><DownOutlined className='recent-btn-icon' style={{ color: '#fff' }} /></button>
      </div>
    </div>
  );
};

export default RecentWorkSlider;
