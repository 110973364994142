import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, ArrowDownOutlined } from '@ant-design/icons'; // Added DownOutlined
import HeaderImg1 from '../assets/images/canvaMain.png';
import HeaderImg2 from '../assets/images/headerimage1.jpg';
import HeaderImg3 from '../assets/images/headerimage3.jpg';
import HeaderImg4 from '../assets/images/header4.jpg';
import '../Styles/Header.css';

// ScrollDownContent component
const ScrollDownContent = () => {
  return (
    <div className="scroll-down-content">
    <p>SCROLL DOWN</p>
    <ArrowDownOutlined style={{ fontSize: '24px', color: '#fff' }} className='down'/>
  </div>
  );
};

const Header = () => {
  const [isZoomed, setIsZoomed] = useState(false);

  // Toggle zoom state automatically every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setIsZoomed(!isZoomed);
    }, 2000);
    return () => clearInterval(interval);
  }, [isZoomed]);
  return (
    <div className="carousel-container">
      {/* Social icons */}
      <div className="social-icons">
        <FacebookOutlined style={{ fontSize: '20px', marginRight: '10px' }} />
        <InstagramOutlined style={{ fontSize: '20px', marginRight: '10px' }} />
        <LinkedinOutlined style={{ fontSize: '20px' }} />
      </div>

      {/* Carousel */}
      <Carousel fade controls={true} indicators={true} nextIcon={<span className="carousel-control-next-icon" />} prevIcon={<span className="carousel-control-prev-icon" />} className="carousel-custom">
        <Carousel.Item>
        <img
        className={`d-block w-100 ${isZoomed ? 'zoom-in' : 'zoom-out'}`}
        src={HeaderImg1}
        alt="First slide"
        style={{ height: '700px' }} // Set max height to 800px
      />
          <Carousel.Caption className="carousel-caption">
            <h1 className="header-title" data-aos="fade-left" data-aos-duration="1000">WE ARE LEADING NAME IN THE <br/>CONSTRUCTION</h1>
            <p data-aos="fade-right" data-aos-duration="1000">Our passion has been to build for eternity<br/>build for sustenance, and build for safety</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
           className={`d-block w-100 ${isZoomed ? 'zoom-in' : 'zoom-out'}`}
            src={HeaderImg2}
            alt="Second slide"
            style={{ height: '700px' }} // Set max height to 800px
          />
          <Carousel.Caption className="carousel-caption">
            <h1 className="header-title" data-aos="fade-left" data-aos-duration="1000">At Raycon & Co Communal<br/>Partnership is Ket</h1>
            <p data-aos="fade-up" data-aos-duration="1000">We engage our best and engage the stakeholders<br/>of our work community to achive our goal</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
           className={`d-block w-100 ${isZoomed ? 'zoom-in' : 'zoom-out'}`}
            src={HeaderImg3}
            alt="tired slide"
            style={{ height: '700px' }} // Set max height to 800px
          />
          <Carousel.Caption className="carousel-caption">
            <h1 className="header-title" data-aos="fade-left" data-aos-duration="1000">The Government most<br/>Trusted Partners</h1>
            <p data-aos="fade-up" data-aos-duration="1000">Haven handled projects for both state and federal government<br/>We are proud to have positioned our self as the best choice</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className={`d-block w-100 ${isZoomed ? 'zoom-in' : 'zoom-out'}`}
            src={HeaderImg4}
            alt="tired slide"
            style={{ height: '700px' }} // Set max height to 800px
          />
          <Carousel.Caption className="carousel-caption ">
            <h1 className="header-title" data-aos="fade-left" data-aos-duration="1000">An Innovative<br/>Business Idea</h1>
            <p data-aos="fade-up" data-aos-duration="1000">What ever your budgets and need is,we are here to<br/>help you plan,work and achieve your desired goal</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
       {/* Scroll Down Content */}
       <ScrollDownContent />
      
    </div>
  );
};

export default Header;
