import React, { useState } from 'react';
import Navbar from '../Components/Navbar';
import { Button } from 'antd';
import '../Styles/Gallery.css';
import Footer from '../Components/Footer';
import ArrowTopNavigation from '../Components/ArrowTopNavigation';
import { Box, Container, Grid, Typography, Card, CardContent } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';

// Import local images
import CategoryOneBanner from '../assets/images/service1.jpg';
import CategoryTwoBanner from '../assets/images/service2.jpg';
import CategoryThreeBanner from '../assets/images/service3.jpg';
import Image1 from '../assets/images/service1.jpg';
import Image2 from '../assets/images/service2.jpg';
import Image3 from '../assets/images/service3.jpg';
import Image4 from '../assets/images/service4.jpg';
import Image5 from '../assets/images/service5.webp';

const Gallery = () => {
    // Local images data for UI demonstration
    const [categories] = useState([
        {
            name: 'Road construction',
            banner: { url: CategoryOneBanner }
        },
        {
            name: 'underground Drainage',
            banner: { url: CategoryTwoBanner }
        },
        {
            name: 'Construction Of Oil Drilling Location',
            banner: { url: CategoryThreeBanner }
        }
    ]);

    const [images] = useState({
        'Category One': [
            { title: 'Image 1', image: { url: Image1 } },
            { title: 'Image 2', image: { url: Image2 } }
        ],
        'Category Two': [
            { title: 'Image 3', image: { url: Image3 } }
        ],
        'Category Three': [
            { title: 'Image 4', image: { url: Image4 } },
            { title: 'Image 5', image: { url: Image5 } }
        ]
    });

    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    const handleBackToCategories = () => {
        setSelectedCategory(null);
    };

    return (
        <div>
            <Navbar />
            <div className="gallery-banner">
                <div className="container gallery-text animation-top-sentence">
                    <h1 className='w3-animate-right'>Gallery</h1>
                </div>
            </div>
            <div style={{ textAlign: 'left', marginTop: '20px' }} className='title container'>
                <h2>Work Gallery</h2>
                <div className='sub-title'>
                    <h4>Past/Current projects</h4>
                    <p>Find a list of work/project gallery of completed/ongoing projects. Our years of experience in construction is our edge and advantage in the industry.</p>
                </div>
            </div>
            <Box sx={{ bgcolor: "#fafafa", minHeight: "100vh" }}>
                <Container sx={{ pt: 5, pb: 5, minHeight: "83vh" }}>
                    <Box sx={{ flexGrow: 1 }}>
                        {selectedCategory ? (
                            <Box>
                                <div className='pb-4'>
                                    <Button onClick={handleBackToCategories}>Back to Categories</Button>
                                </div>
                                <Typography variant="h6" sx={{ mb: 2 }}>{selectedCategory}</Typography>
                                <Grid container spacing={{ xs: 2, md: 3 }} justifyContent="center">
                                    {images[selectedCategory] ? (
                                        images[selectedCategory].map((post, index) => (
                                            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                                <CardMedia
                                                    component="img"
                                                    height="100%"
                                                    width="100%"
                                                    image={post.image.url}
                                                    alt="Post Image"
                                                    style={{ objectFit: 'cover' }}
                                                />
                                                <div className="card-content-para">
                                                    <p>{post.title}</p>
                                                </div>
                                            </Grid>
                                        ))
                                    ) : (
                                        <Typography variant="body1">No posts found for this category.</Typography>
                                    )}
                                </Grid>
                            </Box>
                        ) : (
                            <Grid container spacing={{ xs: 2, md: 3 }} justifyContent="center">
                                {categories.map((category, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <Card
                                            sx={{ cursor: 'pointer', textAlign: 'center', padding: '20px' }}
                                            onClick={() => handleCategoryClick(category.name)}
                                        >
                                            <CardMedia
                                                component="img"
                                                height="200"
                                                image={category.banner.url}
                                                alt="Category Banner"
                                                style={{ objectFit: 'cover' }}
                                            />
                                            <CardContent>
                                                <Typography variant="h6">{category.name}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Box>
                </Container>
            </Box>
            <Footer />
            <ArrowTopNavigation />
        </div>
    );
};

export default Gallery;
