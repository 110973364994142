import React, { useEffect, useState } from 'react';
import { ArrowUpOutlined } from '@ant-design/icons'; 
import Whatsup from '../assets/images/whatsup.png';
import '../Styles/Home.css';

const Arrow = () => {
    // Function to open WhatsApp chat in a new window
    const openWhatsAppChat = () => {
        window.open('https://wa.me/1234567890', '_blank'); // Replace with your WhatsApp number
    };
    
    // Function to scroll to the top of the page smoothly
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
  
    // State to control the visibility of the up arrow
    const [showScroll, setShowScroll] = useState(false);
    
    // Function to check whether to display the up arrow based on scroll position
    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false);
        }
    };
    
    // Effect to add and remove event listener for scroll
    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => window.removeEventListener('scroll', checkScrollTop);
    }, [showScroll]);
    
    return (
        <div>
            {/* Up arrow */}
            <div className={`up-arrow ${showScroll ? 'show' : 'hide'}`} onClick={scrollToTop}>
                <ArrowUpOutlined />
            </div>
            {/* WhatsApp icon */}
            <div className="whatsapp-icon" onClick={openWhatsAppChat}>
                <img
                    src={Whatsup} 
                    alt="WhatsApp Icon"
                    className="whatsapp-icon-img"
                />
            </div>
        </div>
    );
};

export default Arrow;
