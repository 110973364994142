import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Screens/Home';
import About from "./Screens/About";
import ContactUs from './Screens/ContactUs';
import Services from "./Screens/Service";
import Gallery from "./Screens/Gallery";
import Carrer from "./Screens/Carrer";
import Equipments from "./Screens/Equipments";
import NewsMedia from "./Screens/NewsMedia";
import Blog from './Screens/Blog'
import SinglePost from './Components/SinglePost'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Provider } from 'react-redux';
function App() {
  return (
    <div className="App">
       <ToastContainer />
        <ProSidebarProvider> 
   <BrowserRouter>
    <Routes>
    <Route path="/" element={<Home/>} />
    <Route path="/about" element={<About/>} />
    <Route path="/contact" element={<ContactUs/>} />
    <Route path="/services" element={<Services/>} />
    <Route path="/gallery" element={<Gallery/>} />
    <Route path="/blog" element={<Blog/>} />
    <Route path="/career" element={<Carrer/>} />
    <Route path="/equipments" element={<Equipments/>} />
    <Route path="/news-media" element={<NewsMedia/>} />
    <Route path="/post/:id" element={<SinglePost />} />
    </Routes>
    </BrowserRouter>
    </ProSidebarProvider>
    </div>
  );
}

export default App;
