import React from 'react';
import { Row, Col } from 'antd';
import Navbar from '../Components/Navbar';
import '../Styles/Equipments.css';
import equipments1 from '../assets/images/truck.jpg'
import equipments2 from '../assets/images/jcp.jpg'
import equipments3 from '../assets/images/equipments3.jpg'
import equipments4 from '../assets/images/equipments4.jpg'
import equipments5 from '../assets/images/equipments5.jpg'
import equipments6 from '../assets/images/equipments6.jpg'
import Footer  from '../Components/Footer';
import ArrowTopNavigation from '../Components/ArrowTopNavigation'



const Equipments = () => {
  return (
    <div>
      <Navbar />
      <div className="equipmemts-banner">
        <div className="container equipmemts-text animation-top-sentence">
          <h1 className='w3-animate-right'>Equipments</h1>
        </div>
      </div>
      <div className='container pb-3'>
        <div style={{ textAlign: 'left', marginTop: '20px' }} className='title '>
          <h2 data-aos="fade-left" data-aos-duration="1000">Our Equipments</h2>
        </div>
        <Row gutter={[16, 16]} style={{ marginTop: '20px' }}  data-aos="fade-up" data-aos-duration="1000">
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <div className="column-content equipments-contents">
              <img src={equipments1} alt="Equipment 1" />
              <h3>Heavy Duty Dumper Trucks</h3>
              <p>25 - 40 Tonnes Dumper Trucks to Haul material.</p>
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <div className="column-content equipments-contents">
              <img src={equipments2} alt="Equipment 2" />
              <h3>Excavator</h3>
              <p>Mobile CAT Excavator</p>
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <div className="column-content equipments-contents">
              <img src={equipments3} alt="Equipment 3" />
              <h3>Slip Form Machine</h3>
              <p>Equipment to Lay Central Reservation, Kerbs or Walkways.</p>
            </div>
          </Col>
        </Row>
        {/* Repeat the Row for additional equipment columns */}
        <Row gutter={[16, 16]} style={{ marginTop: '20px' }}  data-aos="fade-up" data-aos-duration="1000">
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <div className="column-content equipments-contents">
              <img src={equipments4} alt="Equipment 4" />
              <h3>Asphalt Paving Equipment</h3>
              <p>Paver for Paving Roads</p>
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <div className="column-content equipments-contents">
              <img src={equipments5} alt="Equipment 5" />
              <h3>200 TPH</h3>
              <p>Asphalt Plant Produces 200 Ton Per Hour</p>
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <div className="column-content equipments-contents">
              <img src={equipments6} alt="Equipment 6" />
              <h3>Cat Grader</h3>
              <p>Grader For Sand paving</p>
            </div>
          </Col>
        </Row>
      </div>
          {/* Footer Section Start */}
          <Footer/>
          {/* ArrowTopNavigation Section Start */}
          <ArrowTopNavigation/>
    </div>
  )
}

export default Equipments;
