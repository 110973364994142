import React from 'react';
import { Collapse } from 'antd';
import '../Styles/Collapse.css'

const { Panel } = Collapse;

const items = [
  {
    key: '1',
    label: 'Standard and Dual Carriage Roads',
    content: (
      <p className='collapse-content-para'>
        RAYCON has constructed several good quality Asphaltic and surface dressed road projects for several State governments and International Oil Companies in Nigeria. We have successfully constructed several kilometer of new roads which are in good conditions. Our roads are properly designed by seasoned Engineers and executed with our modern heavy duty equipment to meet international standards.
      </p>
    ),
  },
  {
    key: '2',
    label: 'Construction of Underground drainages',
    content: (
      <p className='collapse-content-para'>
       Raycon have a department dedicated for design and production of solid concrete reinforced pipes of different dimensions for the construction of underground de-watering system. The primary purpose of the department is to remove water from the road and surrounding to its proper destination without damaging or disturbing surface infrastructure. 
      </p>
    ),
  },
  {
    key: '3',
    label: 'Design and Construction of Oil Drilling Location',
    content: (
      <p className='collapse-content-para'>
      Raycon is well known and preferred by major International Oil Companies in the area of preparation of Oil Well drilling locations and camp sites. We have demonstrated high level expertise through the numerous Oil Well location preparation works done for our clients who are highly satisfied and can testify to our good quality of work delivered on scheduled.
      </p>
    ),
  },
  {
    key: '4',
    label: 'Building of Tank Farms',
    content: (
      <p className='collapse-content-para'>
     Coming Soon!
      </p>
    ),
  },
];

const CollapseService = () => (
  <Collapse bordered={false} defaultActiveKey={['1']} style={{ marginBottom: 24 }}>
    {items.map(item => (
      <Panel header={<span style={{ color: '#203556', fontWeight: '600', fontSize: '20px' }}>{item.label}</span>} key={item.key}>
        {item.content}
      </Panel>
    ))}
  </Collapse>
);

export default CollapseService;
