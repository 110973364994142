import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Form} from 'antd';
import Navbar from '../Components/Navbar';
import '../Styles/Contact.css';
import ContactForm from '../Components/ContactForm';
import Footer from '../Components/Footer';
import ArrowTopNavigation from '../Components/ArrowTopNavigation'



const ContactUs = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true
        });
    }, []);

    const [form] = Form.useForm();
    const [submitted, setSubmitted] = useState(false);

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        // Here you can handle form submission, like sending data to a server
        setSubmitted(true);
        // Optionally, reset form fields
        form.resetFields();
    };

    return (
        <div>
            {/* Navbar Start */}
            <Navbar />
            <div className="contact-banner">
                <div className="container banner-text animation-top-sentence">
                    <h1 className='w3-animate-right'>Contact Us</h1>
                </div>
            </div>
             {/* ContactForm Section Start */}
             <ContactForm />
            {/* Footer Section Start */}
            <Footer />
               {/* ArrowTopNavigation Section Start */}
               <ArrowTopNavigation />
        </div>
    );
}

export default ContactUs;
