import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Container, Typography } from '@mui/material';
import moment from 'moment';
import Loader from '../Components/Loader';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
// import { Base_Url } from '../../src/data';

// Function to decode HTML entities
const decodeHtml = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
};

// Function to remove <p> tags from HTML
const removePTags = (html) => {
    return html.replace(/<\/?p>/gi, '');
};

const SinglePost = () => {
    const { id } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);

 

    if (loading) return <Loader />;

    // Process content
    const processedContent = decodeHtml(removePTags(post?.content || ''));

    return (
        <>
            <Navbar />
       
            <Footer />
        </>
    );
};

export default SinglePost;
