import React from 'react';
import { Row, Col } from 'antd';
import Navbar from '../Components/Navbar';
import LatestNews from '../Components/LatestNews';
import Footer from '../Components/Footer';
import ArrowTopNavigation from '../Components/ArrowTopNavigation';
import '../Styles/NewsMedia.css';
import Certificate from '../assets/images/certificate.jpg'; // Correct import path for the image

const NewsMedia = () => {
  return (
    <div>
      <Navbar />
      <div className="news-banner">
        <div className="container news-text animation-top-sentence">
          <h1 className='w3-animate-right'>News/Media</h1>
        </div>
      </div>
      
     

      {/* LatestNews Section Start */}
      <div className='pb-3'>
        <LatestNews />
      </div>
       {/* Container for maintaining spacing */}
      <div className='container'>
        <Row>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} className='certificate pt-5'> 
            <img src={Certificate} alt="Example" className="image scsc" />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}> 
          </Col>
        </Row>
      </div>

      {/* Footer Section Start */}
      <Footer />

      {/* ArrowTopNavigation Section Start */}
      <ArrowTopNavigation />
    </div>
  );
};

export default NewsMedia;
