import React from 'react';
import { Tabs } from 'antd';
import '../Styles/CardCarosel.css'; // Make sure to adjust the path if needed

const onChange = (key) => {
  console.log(key);
};

const items = [
  {
    key: '1',
    label: 'History',
    children: 'Since our inception in 1972. as a construction, erection and engineering contracting company that has been offering professional capabilities, resources and services to the Oil Producing, Building & Civil Engineering, Environmental Engineering, Corrosion/Pollution Control and Prevention Industries in Nigeria, We have positioned ourself as the leading choice for individuals, NGO, cooperate organizations and government agencies for years and counting. This, we have achieved through our collaborative/professional approach to work ',
  },
  {
    key: '2',
    label: 'Mission',
    children: 'Our goal is to become one of the foremost construction companies, with a view of delivering quality jobs and carrying out our projects in the safest manner in line with approved worldwide standard and specifications, and also guaranty clients satisfaction in all our project planning and execution',
  },
  {
    key: '3',
    label: 'Vision',
    children: 'Raycon principal field of expertise is in the fabrication, preparation of oil drilling location, construction of dual carriage and single lane roads, construction of major bridge works, piling works for shore protection, construction of airstrip, underground drainages and all civil / mechanical related works.',
  },
];

const Tab = () => <Tabs data-aos="fade-up" data-aos-duration="1000" defaultActiveKey="1" items={items} onChange={onChange} />;
export default Tab;
