import React from 'react';
import { Row, Col } from 'antd';
import '../Styles/CardCarosel.css'
import { ArrowUpOutlined ,ArrowLeftOutlined,ArrowRightOutlined } from '@ant-design/icons'; 
import Service7 from '../assets/images/service7.jpg'
import Service8 from '../assets/images/service8.jpg'
import Service9 from '../assets/images/service9.jpg'
import Tab from '../Components/Tab'
import Assurance from './Assurance';
import ImageCarousel from './ImageCarousel';
import { Link } from 'react-router-dom';
import { Flex, Progress } from 'antd';
const CardSliderTab = () => {
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const checkNext = () => {
      const labels = document.querySelectorAll('#slider label');
      const nextIndex = selectedIndex === (labels.length - 1) ? 0 : selectedIndex + 1; 
      setSelectedIndex(nextIndex);
    }
  
    const checkPrevious = () => {
      const labels = document.querySelectorAll('#slider label');
      const previousIndex = selectedIndex === 0 ? labels.length - 1 : selectedIndex - 1; 
      setSelectedIndex(previousIndex);
    }
  
    const check = index => setSelectedIndex(index);
  return (
    <div>
         <div className='container'>
         <div className='container latest-news pt-5'>
        <h1 className='latest-title' data-aos="fade-right" data-aos-duration="1000">Industry Insights</h1>
        <div className='sub-title d-flex justify-content-between'>
        <h4 data-aos="fade-up" data-aos-duration="1000">Empowering Industries, Enriching Lives</h4>
        {/* Wrap the "About Us" text with Link */}
        <Link to="/about" className='all-services'>
          <span className="plus-icon">About Us</span> <ArrowUpOutlined />
        </Link>
      </div>
      </div>
         <Row gutter={[16, 16]}>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
      <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 for-padding"  data-aos="fade-right" data-aos-duration="1000">
        <div className="md:w-2/4 md:mb-0 mb-6 flex flex-col text-center items-center">
          <section
            id="slider"
            className="w-16 h-20 inline-flex items-center justify-center mb-5 flex-shrink-0"
          >
            <input
              type="radio"
              name="slider"
              id="s1"
              checked={selectedIndex === 0}
              onClick={() => check(0)}
            />
            <input 
              type="radio" 
              name="slider" 
              id="s2" 
              checked={selectedIndex === 1}
              onClick={() => check(1)}
            />
            <input
              type="radio"
              name="slider"
              id="s3"
              checked={selectedIndex === 2}
              onClick={() => check(2)}
            />
            <label htmlFor="s1" id="slide1">
              <img className="fea" src={Service7} height="100%" width="100%"/>
            </label>
            <label htmlFor="s2" id="slide2">
              <img className="fea" src={Service9} height="100%" width="100%"/>
            </label>
            <label htmlFor="s3" id="slide3">
              <img className="fea" src={Service8} height="100%" width="100%"/>
            </label>
          </section>
        </div>
        <div className="d-flex ">
        <div className="md:w-1/4 py-64 md:mb-0 mb-6 flex flex-col text-center items-center">
          <div className="w-20 h-20 inline-flex items-center justify-center rounded-full text-orange-500 mb-5 flex-shrink-0">
            <button onClick={checkPrevious} className='card-btn'><ArrowLeftOutlined /></button>
          </div>
        </div>
        <div className="md:w-1/4 py-64 md:mb-0 mb-6 flex flex-col text-center items-center">
          <div className="w-20 h-20 inline-flex items-center justify-center rounded-full text-orange-500 mb-5 flex-shrink-0">
            <button onClick={checkNext} className='card-btn'><ArrowRightOutlined /></button>
          </div>
        </div>
        </div>
      </div>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
      <Tab/> 
      {/* Additional content with animation */}
      <div className="additional-content pt-5" data-aos="fade-left" data-aos-duration="1000">
      <div>
      <h5>Successful Projects Completed:</h5>
      <Progress percent={80} status="active" strokeColor="#ff7875" />
      </div>
      <div>
      <h5>Happy Clients:</h5>
      <Progress percent={90} status="active"  strokeColor="#ff7875"/>
      </div>
      <div>
      <h5>Successful Projects Completed:</h5>
      <Progress percent={50} status="active"  strokeColor="#ff7875"/>
      </div>
</div>
      </Col>
    </Row>
    {/* <Assurance/> */}
      </div>
      <ImageCarousel/>
    </div>
   
  );
};

export default CardSliderTab;
