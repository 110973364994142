import React from 'react';
import { Row, Col } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import Man1 from '../assets/images/projects1.jpg';
import Man2 from '../assets/images/projects2.jpg';
import Man3 from '../assets/images/projects3.jpg';


import '../Styles/RecentWork.css';

const Pioneers = () => {
  return (
    <div>
      <div className='container latest-news'>
        <h1 className='latest-title' data-aos="fade-right" data-aos-duration="1000">Our Best Approach</h1>
        <div className='sub-title d-flex justify-content-between'>
          <h4 data-aos="fade-up" data-aos-duration="1000">Stronger Together, Innovating Forever</h4>
        </div>
        <Row gutter={[16, 16]} justify="space-around">
          <Col xs={24} sm={8} className='p-4' data-aos="fade-right" data-aos-duration="3000">
            <div className="image-container">
              <img src={Man1} alt="Image 1" className="pioneer-image" />
              <div className="overlay">
                <div className="text">
                  <h3>We Brainstorm</h3>
                  <p>We deliver true results, focusing on strategic decisions and practical actions tailored to our clients We deliver true results, focusing on strategic adecisions and practical actions tail</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={8} className='p-4' data-aos="fade-up" data-aos-duration="3000">
            <div className="image-container"  style={{ marginTop: '-25px' }}>
              <img src={Man2} alt="Image 2" className="pioneer-image" />
              <div className="overlay">
                <div className="text">
                  <h3>Generate a Blueprint</h3>
                  <p>We deliver true results, focusing on strategic decisions and practical actions tailored to our clients We deliver true results, focusing on strategic 1decisions and practical actions tail</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={8} className='p-4' data-aos="fade-left" data-aos-duration="3000">
            <div className="image-container">
              <img src={Man3} alt="Image 3" className="pioneer-image" />
              <div className="overlay">
                <div className="text">
                  <h3>Execute</h3>
                  <p>We deliver true results, focusing on strategic decisions and practical actions tailored to our clients We deliver true results, focusing on strategic decisions and practical actions tail</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Pioneers;
