import React from 'react';
import { Layout } from 'antd';
import { Row, Col } from 'antd';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation from React Router
import Footerlogo from '../assets/images/footerLogo.png';
import '../Styles/Footer.css';

const { Footer } = Layout;

const CustomFooter = () => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Footer className="custom-footer">
      <div className='container'>
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <div className="footer-column">
              <img src={Footerlogo} alt="logo" className="footer-logo" />
              <div className="footer-address">
                <h3>Contact Us</h3>
                <p className='sub-address'>Main Office</p>
                <p>73 Ogunu Road P.O. Box 568, <br/>Warri Delta State, Nigeria.</p>
                <p  className='sub-address'>Uyo</p>
                <p>Km8 Calabar - Itu Road Uyo, <br/>kwaIbom State, Nigeria.</p>
                <p  className='sub-address'>Abuja</p>
                <p>Cadastral Zone C16, <br/>Idu Industrial Area, Abuja</p>
               
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <div className="footer-column">
              <h3>Useful Links</h3>
              <ul>
                <li><Link to="/" className="custom-links"><h6>Home</h6></Link></li>
                <li><Link to="/about" className="custom-links"><h6>About Us</h6></Link></li>
                <li><Link to="/services" className="custom-links"><h6>Services</h6></Link></li>
                <li><Link to="/blog" className="custom-links"><h6>Blog</h6></Link></li>
                <li><Link to="/gallery" className="custom-links"><h6>Gallery</h6></Link></li>
                <li><Link to="/equipments" className="custom-links"><h6>Equipment</h6></Link></li>
                <li><Link to="/career" className="custom-links"><h6>Career</h6></Link></li>
                <li><Link to="/contact" className="custom-links"><h6>Contact Us</h6></Link></li>
              </ul>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <div className="footer-column">
              <h3>Opening Hours</h3>
              <p>Mon - Tues: 8.00 am - 5.00 pm</p>
              <p>Wednes - Thurs: 8.00 am - 5.00 pm</p>
              <p>Fri: 8.00 am - 4.00 pm</p>
              <p>Sat & Sun: Closed But Onsite</p>
              <p><PhoneOutlined /> +234-803-5487672</p>
                <p><MailOutlined /> enquiries@raycon.net</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="copyright pt-4">
        &copy; 2024 - Raycon - Powered by <a href='https://techslideitsolutions.com/' style={{ color: '#be1e2d' ,fontSize:'18'}}> TechSlide ITS</a>
      </div>
    </Footer>
  );
}

export default CustomFooter;
