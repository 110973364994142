import React, { useState } from 'react';
import { Row, Col, Typography } from 'antd';
import GoalImg from '../assets/images/serviceimg11.jpg';
import Principle from '../assets/images/principal.jpg'
import CollapseService from '../Components/CollapseSerive';
import CollapseService2 from '../Components/Collapseservice2';
import Navbar from '../Components/Navbar';
import '../Styles/Service.css'
import Owlcarousel from '../Components/Owlcarousel';
import Connect from '../Components/Connect';
import Footer  from '../Components/Footer';
import ArrowTopNavigation from '../Components/ArrowTopNavigation'
import ServicesType from '../Components/ServicesType';
import ServiceEnhineer from '../Components/ServiceEnhineer';
import Pioneers from '../Components/Pioneers';
import { PlusOutlined, ArrowUpOutlined  } from '@ant-design/icons'; 
import { Link } from 'react-router-dom'; 
import '../Styles/About.css'; 
import { Collapse } from 'antd';
import '../Styles/Collapse.css'

const { Panel } = Collapse;

const {  Paragraph } = Typography;

const itemss = [
  {
    key: '1',
    label: 'Construction of Airstrip',
    content: (
      <p className='collapse-content-para'>
      We have constructed several Helipads for the Oil Sector, it was a big achievement when we were awarded the contract to construct the AirStrip for Shell Petroleum Company in Forcados Terminal, Delta State. Apart from being an airstrip, it was an airstrip in a remote island in Delta State and the only access to it was by water, 95% of the material had to be moved by barges with more than a 1000 trips of 500 tonnes.
      </p>
    ),
  },
  {
    key: '2',
    label: 'Construction of Bridges',
    content: (
      <p className='collapse-content-para'>
     Bridge construction is among our major area of specialization. We have been involved in construction of bridges since the inception of our company. We have constructed various bridges across the country ranging from single span to multi span steel deck and concrete types of bridges.
      </p>
    ),
  },
  {
    key: '3',
    label: 'Building of Houses',
    content: (
      <p className='collapse-content-para'>
        Building of solid houses and field logistic base has been an integral part of Raycon. We have partake in building of fields logistic base for International Oil Companies on land and offshore. We also have a wide range of building products and systems which are aimed primarily at groups of building types or markets. The design process for buildings is highly organized and draws upon research establishments that study material properties and performance, code officials who adopt and enforces
      </p>
    ),
  },
  {
    key: '4',
    label: 'Shore Protection',
    content: (
      <p className='collapse-content-para'>
     We are highly experience in shore protection and restoration works. Our projects involved the protection of the coast and the shore against the erosive forces of waves, current and storm surge. We have implored high technical measures in protection of the coast and hinterland against flooding mainly in the Niger Delta Area of Nigeria.
      </p>
    ),
  },
  {
    key: '5',
    label: 'Pump Houses & Metering Stations ',
    content: (
      <p className='collapse-content-para'>
     Coming Soon!
      </p>
    ),
  },
];

const items = [
  {
    key: '1',
    label: 'Standard and Dual Carriage Roads',
    content: (
      <p className='collapse-content-para'>
        RAYCON has constructed several good quality Asphaltic and surface dressed road projects for several State governments and International Oil Companies in Nigeria. We have successfully constructed several kilometer of new roads which are in good conditions. Our roads are properly designed by seasoned Engineers and executed with our modern heavy duty equipment to meet international standards.
      </p>
    ),
  },
  {
    key: '2',
    label: 'Construction of Underground drainages',
    content: (
      <p className='collapse-content-para'>
       Raycon have a department dedicated for design and production of solid concrete reinforced pipes of different dimensions for the construction of underground de-watering system. The primary purpose of the department is to remove water from the road and surrounding to its proper destination without damaging or disturbing surface infrastructure. 
      </p>
    ),
  },
  {
    key: '3',
    label: 'Design and Construction of Oil Drilling Location',
    content: (
      <p className='collapse-content-para'>
      Raycon is well known and preferred by major International Oil Companies in the area of preparation of Oil Well drilling locations and camp sites. We have demonstrated high level expertise through the numerous Oil Well location preparation works done for our clients who are highly satisfied and can testify to our good quality of work delivered on scheduled.
      </p>
    ),
  },
  {
    key: '4',
    label: 'Building of Tank Farms',
    content: (
      <p className='collapse-content-para'>
     Coming Soon!
      </p>
    ),
  },
];

const Service = () => {
  const [animation, setAnimation] = useState('move-top');

  // Function to toggle between animations
  const toggleAnimation = () => {
    setAnimation((prevAnimation) => (prevAnimation === 'move-top' ? 'move-bottom' : 'move-top'));
  };

  // Function to scroll to the "Precision Engineering for a Better World" section
  const scrollToEngineeringSection = (id) => {
    const engineeringSection = document.getElementById(id);
    if (engineeringSection) {
      engineeringSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const scrollToEngineeringSections = (id) => {
    const engineeringSection = document.getElementById(id);
    if (engineeringSection) {
      engineeringSection.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <div>
    <Navbar/>
    <div className="service-banner">
     <div className="container service-text animation-top-sentence">
        <h1 className='w3-animate-right'>Services</h1>
    </div>
    </div>
    <div className='container pb-3'>
       <div style={{ textAlign: 'left', marginTop: '20px' }} className='title ' >
          <h2 data-aos="fade-left" data-aos-duration="1000">Our Services</h2>
        </div>
       {/* ServicesType Section Start */}
        <div>
         <div className='sub-title d-flex justify-content-between'>
        <h4 data-aos="fade-up" data-aos-duration="1000">Few Words About Raycon & Company .</h4>
        {/* Wrap the "All Services" text with Link */}
        <Link to="/services" className='all-services'>
          <span className="plus-icon">All Services</span> <ArrowUpOutlined />
        </Link>
      </div>
      <Row gutter={[16, 16]} className='p-4' data-aos="fade-up-right" data-aos-duration="1000"> {/* gutter defines the spacing between columns and rows */}
        {/* First Column */}
        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
     <div className="service-item" onClick={() => scrollToEngineeringSection('collapseI')}>
            <span className="plus-icon">< PlusOutlined /></span>
            <div className="service-name"><h6>Standard and Dual Carriage Roads</h6></div>
          </div>
          <div className="service-item" onClick={() => scrollToEngineeringSection('collapseI')}>
            <span className="plus-icon"><PlusOutlined /></span>
            <div  className="service-name"><h6>Construction of Underground drainages</h6></div>
          </div>
          <div className="service-item" onClick={() => scrollToEngineeringSection('collapseI')}>
            <span className="plus-icon"><PlusOutlined /></span>
            <div  className="service-name"><h6>Design and Construction of Oil Drilling Location</h6></div>
          </div>
        </Col>
        {/* Second Column */}
        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
          <div className="service-item" onClick={() => scrollToEngineeringSections('collapse2')}>
            <span className="plus-icon"><PlusOutlined /></span>
            <div  className="service-name"><h6>Construction of Airstrip</h6></div>
          </div>
          <div className="service-item" onClick={() => scrollToEngineeringSection('collapseI')}>
            <span className="plus-icon"><PlusOutlined /></span>
            <div  className="service-name"><h6>Building of Tank Farms</h6></div>
          </div>
          <div className="service-item"  onClick={() => scrollToEngineeringSections('collapse2')}>
            <span className="plus-icon"><PlusOutlined /></span>
            <div  className="service-name"><h6>Pump Houses & Metering Stations </h6></div>
          </div>
        </Col>
        {/* Third Column */}
        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
          <div className="service-item"  onClick={() => scrollToEngineeringSections('collapse2')}>
            <span className="plus-icon"><PlusOutlined /></span>
            <div  className="service-name"><h6>Construction of Bridges</h6></div>
          </div>
          <div className="service-item"  onClick={() => scrollToEngineeringSections('collapse2')}>
            <span className="plus-icon"><PlusOutlined /></span>
            <div  className="service-name"><h6>Building of Houses</h6></div>
          </div>
          <div className="service-item"  onClick={() => scrollToEngineeringSections('collapse2')}>
            <span className="plus-icon"><PlusOutlined /></span>
            <div  className="service-name"><h6>Shore Protection</h6></div>
          </div>
        </Col>
      </Row>
    </div>
       {/* ServiceEnhineer Section Start */}
       <div className='sub-title'>
      <div style={{ textAlign: 'left', marginTop: '20px' }} className='title' data-aos="fade-left" data-aos-duration="1000">
        <h2>Precision Engineering for a Better World</h2>
      </div>
      <Row gutter={[16, 16]} justify="center" className='pt-5' id='collapseI'>
        <Col xs={24} sm={12} style={{ display: 'flex', alignItems: 'flex-start' }}>
          <img src={GoalImg} style={{ maxWidth: '100%', animation: `${animation} 3s ease-in-out infinite`,height:'500px' }} onClick={toggleAnimation} />
        </Col>
        <Col xs={24} sm={12}  data-aos="fade-left" data-aos-duration="1000" className='p-5'>
        <Collapse bordered={false} defaultActiveKey={['1']} style={{ marginBottom: 24 }}>
    {items.map(item => (
      <Panel header={<span style={{ color: '#203556', fontWeight: '600', fontSize: '20px' }}>{item.label}</span>} key={item.key}>
        {item.content}
      </Panel>
    ))}
  </Collapse>
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify="center" id='collapse2'>
        <Col xs={24} sm={12}   data-aos="fade-right" data-aos-duration="1000">
          <div className='pt-5'>
          <Collapse bordered={false} defaultActiveKey={['1']} style={{ marginBottom: 24 }} id='collapseI1'>
    {itemss.map(item => (
      <Panel header={<span style={{ color: '#203556', fontWeight: '600', fontSize: '20px' }}>{item.label}</span>} key={item.key}>
        {item.content}
      </Panel>
    ))}
  </Collapse>
          </div>
         </Col>
        <Col xs={24} sm={12} style={{ display: 'flex', alignItems: 'flex-start' }}>
          <img src={Principle} style={{ maxWidth: '100%', animation: `${animation} 3s ease-in-out infinite` ,height:'500px'}} onClick={toggleAnimation} />
        </Col>
      </Row>
    </div>
    
    </div>
       {/* Pioneers Section Start */}
        <Pioneers/>
       {/* Connect Section Start */}
            <Connect/>
        {/* Footer Section Start */}
         <Footer/>
          {/* ArrowTopNavigation Section Start */}
          <ArrowTopNavigation/>



</div>
  )
}

export default Service;
