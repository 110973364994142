import React, { useState } from 'react';
import { Row, Col, Typography } from 'antd';
import GoalImg from '../assets/images/goal.jpg';
import Principle from '../assets/images/principal.jpg'
import CollapseService from './CollapseSerive';
import CollapseService2 from './Collapseservice2';

import '../Styles/About.css'; 

const {  Paragraph } = Typography;

const ServiceEnhineer = () => {
  const [animation, setAnimation] = useState('move-top');

  // Function to toggle between animations
  const toggleAnimation = () => {
    setAnimation((prevAnimation) => (prevAnimation === 'move-top' ? 'move-bottom' : 'move-top'));
  };

  return (
    <div className='sub-title'>
      <div style={{ textAlign: 'left', marginTop: '20px' }} className='title' data-aos="fade-left" data-aos-duration="1000">
        <h2>Precision Engineering for a Better World</h2>
      </div>
      <Row gutter={[16, 16]} justify="center" className='pt-5'>
        <Col xs={24} sm={12} style={{ display: 'flex', alignItems: 'flex-start' }}>
          <img src={GoalImg} style={{ maxWidth: '100%', animation: `${animation} 3s ease-in-out infinite`,height:'500px' }} onClick={toggleAnimation} />
        </Col>
        <Col xs={24} sm={12}  data-aos="fade-left" data-aos-duration="1000" className='p-5'>
          <CollapseService/>
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={12}   data-aos="fade-right" data-aos-duration="1000">
          <div className='pt-5'>
        <CollapseService2/>
          </div>
         </Col>
        <Col xs={24} sm={12} style={{ display: 'flex', alignItems: 'flex-start' }}>
          <img src={Principle} style={{ maxWidth: '100%', animation: `${animation} 3s ease-in-out infinite` ,height:'500px'}} onClick={toggleAnimation} />
        </Col>
      </Row>
    </div>
  );
};

export default ServiceEnhineer;
