import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, message } from 'antd';
import { EnvironmentOutlined, MailOutlined, PhoneOutlined, FacebookOutlined, InstagramOutlined, LinkedinOutlined } from '@ant-design/icons';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../Styles/Contact.css';
// import { Base_Url } from '../../src/data';

const ContactForm = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true
        });
    }, []);

    const [form] = Form.useForm();
    const [submitted, setSubmitted] = useState(false);

    // const onFinish = async (values) => {
    //     console.log('Form values:', values);
    //     try {
    //         const response = await axios.post(`${Base_Url}/api/contact`, values);
    //         console.log(response.data);
    //         setSubmitted(true);
    //         form.resetFields();
    //         message.success('Email sent successfully');
    //     } catch (error) {
    //         console.error('Error submitting form:', error);
    //         message.error('Failed to send email');
    //     }
    // };

    return (
        <div className="contact-form-wrapper">
            <div className="container mt-4">
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <div style={{ textAlign: 'left', marginTop: '20px' }} className='title'>
                            <h2>Let's Talk</h2>
                        </div>
                        <div className='sub-title' data-aos="fade-up">
                            <h4>Got a project on your mind? Let's discuss the details.</h4>
                        </div>
                        <p className='contact-para' data-aos="fade-up">
                            We try our best to respond to all enquiries within the shortest possible time. Rest assured, we will respond to your message promptly. Thank you!
                        </p>
                        <div className="contact-number" data-aos="fade-up">
                            <PhoneOutlined style={{ fontSize: '24px', color: '#949db4', marginRight: '10px' }} />
                            <span className='contact-numbers'>Cell: +1234567890</span>
                        </div>
                        <div className="contact-info">
                            <div className="contact-item" data-aos="fade-up">
                                <EnvironmentOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
                                <span>Main Office</span>
                                <span>Ogunu Road P.O. Box 568, Warri Delta State, Nigeria.</span>
                            </div>
                            <div className="contact-item" data-aos="fade-up">
                                <EnvironmentOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
                                <span>Uyo</span>
                                <span>Km8 Calabar - Itu Road Uyo, Akwa Ibom State, Nigeria.</span>
                            </div>
                            <div className="contact-item" data-aos="fade-up">
                                <EnvironmentOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
                                <span>Abuja</span>
                                <span>Cadastral Zone C16, Idu Industrial Area, Abuja</span>
                            </div>
                            <div className="contact-item" data-aos="fade-up">
                                <MailOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
                                <span>Email Address</span>
                                <span>enquiries@raycon.net</span>
                                <div className="contact-social-icons">
                                    <a href="https://www.facebook.com/example" aria-label="Facebook">
                                        <FacebookOutlined style={{ fontSize: '24px', color: '#949db4', marginRight: '10px' }} />
                                    </a>
                                    <a href="https://www.instagram.com/example" aria-label="Instagram">
                                        <InstagramOutlined style={{ fontSize: '24px', color: '#949db4', marginRight: '10px' }} />
                                    </a>
                                    <a href="https://www.linkedin.com/example" aria-label="LinkedIn">
                                        <LinkedinOutlined style={{ fontSize: '24px', color: '#949db4', marginRight: '10px' }} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className='pb-5'>
                        <div className="contact-form" data-aos="fade-left">
                            <h2 className='form-title'>Contact Form</h2>
                            <Form form={form} layout="vertical">
                                <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter your name' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please enter your email' }, { type: 'email', message: 'Please enter a valid email' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="subject" label="Subject" rules={[{ required: true, message: 'Please enter the subject' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="phone" label="Phone Number" rules={[{ required: true, message: 'Please enter your phone number' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="message" label="Message" rules={[{ required: true, message: 'Please enter your message' }]}>
                                    <Input.TextArea />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Send</Button>
                                    <Button type="default" htmlType="button" onClick={() => form.resetFields()}>Reset</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default ContactForm;
