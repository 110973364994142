import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CountUp from 'react-countup';
import { Col, Row, Statistic } from 'antd';
import '../Styles/Home.css'; 

const formatter = (value) => <CountUp end={value} separator="," />;

const valueStyles = {
  borderBottom: '1px solid #ececec',
  fontSize: '120px',
  fontWeight: '700',
  WebkitTextStroke: '2px #363636',
  color: 'transparent',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textAlign: 'center',
};
const valueStyle = {
  borderBottom: '1px solid #ececec',
  fontSize: '120px',
  fontWeight: '700',
  color: '#203556', // Quotes around 'transparent'
  WebkitBackgroundClip: 'text', // Capitalize 'B' in 'BackgroundClip'
  overflow: 'hidden',
  textAlign: 'center',
};


const Statistics = () => (
  
  <div className='container'>
    {/* <h1 className='statistic-main-title'>See Some Records In Numbers</h1> */}
    <Row gutter={[16, 16]} data-aos="fade-up-right"  data-aos-duration="1000">
      <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
        <Statistic 
          value={20} 
          suffix="+" 
          valueStyle={valueStyles} 
          formatter={formatter}
        />
        <h5 className='statistic-title'>Happy Clients</h5>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
        <Statistic 
          value={52} 
          suffix="+" 
          valueStyle={valueStyle} 
          formatter={formatter}
        />
        <h5 className='statistic-title'>Years of Experience</h5>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
        <Statistic 
          value={48} 
          valueStyle={valueStyles} 
          formatter={formatter}
        />
        <h5 className='statistic-title'>Projects Completed</h5>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
        <Statistic 
          value={3} 
          valueStyle={valueStyle} 
          formatter={formatter}
        />
        <h5 className='statistic-title'>Certificate of Completion</h5>
      </Col>
    </Row>
  </div>
);

export default Statistics;
