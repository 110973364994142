import React, { useState } from 'react';
import { Row, Col, Typography } from 'antd';
import GoalImg from '../assets/images/goal.jpg';
import Principle from '../assets/images/principal.jpg'
import '../Styles/About.css'; 

const { Title, Paragraph } = Typography;

const GoalPrinciple = () => {
  const [animation, setAnimation] = useState('move-top');

  // Function to toggle between animations
  const toggleAnimation = () => {
    setAnimation((prevAnimation) => (prevAnimation === 'move-top' ? 'move-bottom' : 'move-top'));
  };

  return (
    <div className='sub-title'>
      <div style={{ textAlign: 'left', marginTop: '20px' }} className='title' data-aos="fade-left" data-aos-duration="1000">
        <h2>Raycon Goals And Principle</h2>
      </div>
      <Row gutter={[16, 16]} justify="center" className='pt-5'>
        <Col xs={24} sm={12} style={{ display: 'flex', alignItems: 'flex-start' }}>
          <img src={GoalImg} style={{ maxWidth: '100%', animation: `${animation} 3s ease-in-out infinite` }} onClick={toggleAnimation} />
        </Col>
        <Col xs={24} sm={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} data-aos="fade-left" data-aos-duration="1000">
          <h2 style={{ paddingTop:'50px' }} className='about-content-title'>Raycon's Business Goal</h2>
          <Paragraph style={{ paddingTop:'10px', }} className='about-content-para para-justify'>
            Our goal is to become one of the foremost construction companies, with a view of delivering quality jobs and carrying out our projects in the safest manner in line with approved worldwide standard and specifications, and also guarantee clients satisfaction in all our project planning and execution.
          </Paragraph>
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}  data-aos="fade-right" data-aos-duration="1000">
          <h2 style={{ paddingTop:'50px' }} className='about-content-title'>Raycon's Business Principle</h2>
          <Paragraph style={{ paddingTop:'10px', }} className='about-content-para para-justify'>
            Raycon's principal field of expertise is in the fabrication, preparation of oil drilling location, construction of dual carriage and single lane roads, construction of major bridge works, piling works for shore protection, construction of airstrip, underground drainages and all civil / mechanical related works.
          </Paragraph>
        </Col>
        <Col xs={24} sm={12} style={{ display: 'flex', alignItems: 'flex-start' }}>
          <img src={Principle} style={{ maxWidth: '100%', animation: `${animation} 3s ease-in-out infinite` }} onClick={toggleAnimation} />
        </Col>
      </Row>
    </div>
  );
};

export default GoalPrinciple;
