import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom'; // Import Link from React Router
import '../Styles/Home.css';
import ConnectBg from '../assets/images/cons.png';
import { ArrowUpOutlined } from '@ant-design/icons';

const Connect = () => {
  const [typedText, setTypedText] = useState('');
  const targetText = "Contact with Roycon and Company";

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      setTypedText(targetText.substring(0, currentIndex + 1));
      currentIndex++;
      if (currentIndex === targetText.length) clearInterval(intervalId);
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <div className='container latest-news pt-5'>
        <h1 className='latest-title' data-aos="fade-right" data-aos-duration="1000">Let's Connect</h1>
        <div className='sub-title d-flex justify-content-between'>
          <h4 data-aos="fade-up" data-aos-duration="1000">Raycon and Company Start with a Simple Contact</h4>
          <Link to="/contact" className='all-services'>
          <span className="plus-icon">Contact Us</span> <ArrowUpOutlined />
        </Link>
        </div>
      </div>
      <div className='connect-container' style={{ backgroundImage: `url(${ConnectBg})`, backgroundSize: 'cover' }} data-aos="fade-up" data-aos-duration="1000">
        <div className="contents">
          <h1 className='connect-title' data-aos="fade-up" data-aos-duration="1000">Have a project in mind? </h1>
          <p className='connect-subtitle pt-2' data-aos="fade-up" data-aos-duration="1000">{typedText}</p>
          {/* Use Link component to navigate to the contact page */}
          <Link to="/contact">
            <Button data-aos="fade-up" data-aos-duration="1000" style={{ background: 'none', color: 'white', fontSize: '18px', width: '160px', height: '50px' }} className='button'>Let's Talk</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Connect;
