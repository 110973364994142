import React from 'react'
import { ArrowUpOutlined  } from '@ant-design/icons'; 
import RecentWorkSlider from './RecentWorkSlider';

const RecentWork = () => {
  return (
    <div>
         <div className='container latest-news'>
        <h1 className='latest-title' data-aos="fade-right" data-aos-duration="1000">Recent Works</h1>
        <div className='sub-title d-flex justify-content-between'>
        <h4 data-aos="fade-up" data-aos-duration="1000">Innovating Industry, Inspiring Growth</h4>
        <h5 className='all-services'>
          <span className="plus-icon">Latest Projects</span> < ArrowUpOutlined  />
        </h5>
      </div>
      </div>
      <RecentWorkSlider/>
    </div>
  )
}

export default RecentWork
