import React, { useEffect } from 'react';
import Navbar from '../Components/Navbar';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from '../Components/HeaderCarosel';
import { Row, Col } from 'antd';
import '../Styles/Home.css';
import Goal from '../assets/images/goal.jpg'
import Principal from '../assets/images/principal.jpg'
import Owlcarousel from '../Components/Owlcarousel';
import Collapse from '../Components/Collapse'
import OurTeam from '../Components/OurTeam';
import Statistics from '../Components/Statistics'
import RecentWork from '../Components/RecentWork';
import LatestNews from '../Components/LatestNews';
import Connect from '../Components/Connect';
import Brandlogo from '../Components/Brandlogo';
import CardSliderTab from '../Components/CardSliderTab';
import ArrowTopNavigation from '../Components/ArrowTopNavigation'
import Footer  from '../Components/Footer';
import ServicesType from '../Components/ServicesType';
import Pioneers from '../Components/Pioneers';

const Home = () => {
  useEffect(() => {
    AOS.init({
      // Global settings
      duration: 1000,
      once: true // Whether animation should happen only once
    });
  }, []);
  return (
    <div>
      {/* Navbar Start */}
      <Navbar />
      {/* Header Start */}
      <Header />
      {/* Content Start */}
      <div className='container'>
        <div style={{ textAlign: 'left', marginTop: '20px' }} className='title' >
          <h2 data-aos="fade-left" data-aos-duration="1000">Welcome to Raycon & <br />Company Nigeria Limited.</h2>
        </div>
          <ServicesType/>
         {/* Statistics Section Start */}
       <Statistics/>
        {/* Grid for two columns */}
        <Row gutter={[16, 16]} className='pt-5'>
          {/* Column 1 */}
          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
            <div className="column-content" data-aos="fade-right" data-aos-duration="1000">
              <img src={Goal} alt="Image 1" />
              <h3>Raycon's Business Goal</h3>
              <p>Our goal is to become one of the foremost construction companies, with a view of delivering quality jobs and carrying out our projects in the safest manner in line with approved worldwide standard and specifications, and also guaranty clients satisfaction in all our project planning and execution</p>
            </div>
          </Col>
          {/* Column 2 */}
          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
            <div className="column-content" data-aos="fade-left" data-aos-duration="1000">
              <img src={Principal} alt="Image 2" />
              <h3>Raycon's Business Principle</h3>
              <p>Raycon's principal field of expertise is in the fabrication, preparation of oil drilling location, construction of dual carriage and single lane roads, construction of major bridge works, piling works for shore protection, construction of airstrip, underground drainages and all civil / mechanical related works.</p>
            </div>
          </Col>
        </Row>
      {/* Why Choose Raycon */}
      {/* <div className='choosen-raycons pt-5'>
       <Collapse/>
      </div> */}
      </div>
          {/* Our Team Section Start */}
          <OurTeam/>
        {/* CardSliderTab Section Start */}
        <CardSliderTab/>
         {/* RecentWork Section Start */}
         <RecentWork/>
    {/* <div className='container'> */}
         {/* We Have Been Providing Special Services For 45+ Years */}
         {/* <Owlcarousel/> */}
    {/* </div> */}
    
      {/* Testimonials Section Start */}
      {/* <Testimonials/> */}
       
      {/* LatestNews Section Start */}
       <LatestNews/>
      {/* Pioneers Section Start */}
      <Pioneers/>
       {/* Connect Section Start */}
         <Connect/>
          {/* Brandlogo Section Start */}
          {/* <Brandlogo/> */}
        {/* Footer Section Start */}
         <Footer/>
          {/* ArrowTopNavigation Section Start */}
          {/* <ArrowTopNavigation/> */}
    </div>
  );
};

export default Home;
