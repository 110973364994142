import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Styles/CardCarosel.css';

const ImageCarousel = () => {
  // Dummy images for demonstration
  const images = [
    'https://img.freepik.com/free-photo/civil-engineer-construction-worker-manager-holding-digital-tablet-blueprints-talking-planing-about-construction-site-cooperation-teamwork-concept_640221-156.jpg?size=626&ext=jpg&ga=GA1.1.1700460183.1712275200&semt=ais',
    'https://media.istockphoto.com/id/1456699734/photo/the-engineer-talks-to-the-contractor-to-supervise-and-plan-the-work.jpg?s=612x612&w=0&k=20&c=DCdd8ZpB6A2y6u4mHtvVO9XOfkpS-TqwFaJKq_4Eos4=',
    'https://img.freepik.com/free-photo/civil-engineer-construction-worker-manager-holding-digital-tablet-blueprints-talking-planing-about-construction-site-cooperation-teamwork-concept_640221-298.jpg',
    'https://img.freepik.com/free-photo/building-construction-worker-site-with-architect_23-2149124252.jpg',
    'https://media.istockphoto.com/id/1125525958/photo/drone-operated-by-construction-worker.webp?b=1&s=170667a&w=0&k=20&c=uWLs8M727diJebnGPCQDiZz9822TrcUAYQErGdfYcg4='
  ];

  // OwlCarousel options with pagination and autoplay
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    dots: true, // Pagination dots
    autoplay: true, // Enable autoplay
    autoplayTimeout: 2000, // Autoplay interval in milliseconds
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 4
      }
    },
    // Inline styles for pagination dots
    dotStyle: {
      background: 'red', // Set pagination dots color to red
      width: '10px', // Set pagination dot width
      height: '10px', // Set pagination dot height
      margin: '0 5px' // Set margin between pagination dots
    }
  };

  return (
    <div className='pt-5 pb-3 image-slider'>
      <OwlCarousel className="owl-theme" {...options}>
        {images.map((image, index) => (
          <div className="item" key={index}>
            <div className="image-container">
              <img src={image} alt={`Image ${index}`} style={{ maxWidth: '100%', height: '300px' }}/>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default ImageCarousel;
