import React from 'react';
import '../Styles/Home.css'; 

const OurTeam = () => {
  return (
    <div className="ourTeamContainer">
      <div className="overlay"></div>
      <div className="content">
        <h1 className="animatedText">Our Team - Meet Our Amazing Team Members</h1>
        <p>We are always available to listen to you, brainstorm with you, and put the lasting touch needed to give your project the edge it deserves.</p>
      </div>
    </div>
  );
};

export default OurTeam;
